import React, { Component } from 'react';
import GestGemsysService from '../service/GestGemsysService';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { InputNumber } from 'primereact/inputnumber';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';

export class FormDepense extends Component {
    constructor() {
        super();
        this.state = {
            rtypeDep: 'Tous',
            rpd1: new Date(),
            rpd2: new Date(),
            numDep: '',
            dateDep: new Date(),
            montantDep: 0,
            total: 0,
            motif: '',
            typeDep: '',
            liste_type: [],
           /*  rtypes: [ 
                { label: 'Dépenses de personnel', value: 'Dépenses de personnel' },
                { label: 'Fournitures et consommables', value: 'Fournitures et consommables' },
                { label: 'Consommations diverses', value: 'Consommations diverses' },
                { label: 'Transferts sociaux', value: 'Transferts sociaux' },
                { label: 'Entretiens et maintenances', value: 'Entretiens et maintenances' },
                { label: 'Recherches et formations', value: 'Recherches et formations' },
                { label: 'Investissements', value: 'Investissements' },
                { label: 'Maintenance', value: 'Maintenance' },
                { label: 'Déjeuner', value: 'Déjeuner' },
                { label: 'Assistance sociale ', value: 'Assistance sociale ' },
                { label: 'Mission ', value: 'Mission ' },
                { label: 'Formation', value: 'Formation' },
                { label: 'Achat divers', value: 'Achat divers' },
                { label: 'Energie ', value: 'Energie ' },
                { label: 'Autres ', value: 'Autres ' }
            ], */
            liste_depenses: [],
            selectedDepense: null,
            msg: '',
            modif: false,
            showindicator: false,
            visible: false
        };
        this.edit_depense = this.edit_depense.bind(this);
        this.conf_delete_depense = this.conf_delete_depense.bind(this);
        this.gestGemsysService = new GestGemsysService();

    }


    componentDidMount() {
        this.get_liste_type_depense();
        this.get_liste_depenses();
    }
 
     /* Récupération de la liste globale des types */
     get_liste_type_depense() {
        this.setState({ showindicator: true , liste_type: []});
        this.gestGemsysService.get_liste_codification('TYPE_DEPENSE').then(data => {
            this.setState({ showindicator: false });
            if (data !== undefined && data !== null) {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.id, label: x.libelleCodif, id: x.id }
                    });
                    this.setState({ liste_type: liste });
                }
            }
        });
    }


    get_liste_depenses() {
        let rtype = 'Tous';
        this.setState({ showindicator: true,total: 0 });
        let spd1 = moment(this.state.rpd1, 'DD/MM/YYYY').format('DD/MM/YYYY');
        let spd2 = moment(this.state.rpd2, 'DD/MM/YYYY').format('DD/MM/YYYY');
        if (this.state.rtypeDep !== undefined && this.state.rtypeDep !== null && this.state.rtypeDep !== '') {
            rtype = this.state.rtypeDep;
        }
        this.gestGemsysService.get_liste_depenses_by_criteria(spd1, spd2, rtype).then(data => {
            this.setState({ liste_depenses: data,showindicator: false });
            if (data !== null && data !== undefined) {
                if (data.length > 0) {
                    let initialValue = 0;
                    let total = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.montantDep
                    }, initialValue);
                    this.setState({total: this.gestGemsysService.formaterValueSep(total)});
                }
            }
        });
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annuler_depense();
            this.componentDidMount();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({showindicator: false});
    }

    edit_depense(data) {
        if (data != null && data != undefined) {
            this.setState({ numDep: data.numDep });
            this.setState({ dateDep: new Date(moment(data.dateDep, 'DD/MM/YYYY').format('YYYY-MM-DD')) });
            this.setState({ montantDep: data.montantDep });
            this.setState({ motif: data.motif });
            this.setState({ anneed: data.anneed });
            this.setState({ typeDep: data.idTypeDep.id });
            this.setState({ modif: true });
            this.setState({ selectedDepense: data });
            window.scrollTo(0, 0);
        }
    }

    annuler_depense() {
        this.setState({ numDep: '' });
        this.setState({ dateDep: '' });
        this.setState({ montantDep: '' });
        this.setState({ motif: '' });
        this.setState({ typeDep: null });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    enreg_depense() {
        if (this.state.dateDep == "" || this.state.montantDep == "" || this.state.typeDep == ""|| this.state.typeDep == null) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({showindicator: true});
            if (this.state.modif === false) {
                let depense = {
                    numDep: '0',
                    dateDep: moment(this.state.dateDep, 'DD/MM/YYYY').format('DD/MM/YYYY'),
                    montantDep: this.state.montantDep,
                    anneed: this.gestGemsysService.get_rannee(),
                    motif: this.state.motif,
                    idTypeDep: {id: this.state.typeDep}
                };
                this.gestGemsysService.AddDepenses(depense).then(data => this.resultat(data.code, data.contenu));
            } else {
                let depense = this.state.selectedDepense;
                depense.montantDep = this.state.montantDep;
                depense.motif = this.state.motif;
                depense.idTypeDep = {id: this.state.typeDep};
                depense.dateDep = moment(this.state.dateDep, 'DD/MM/YYYY').format('DD/MM/YYYY');
                this.gestGemsysService.UpdateDepenses(depense).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }
    actionTemplate(rowData) {
        return <div className="grid" style={{ width: 90 }}>
            <div className="col"><Button onClick={() => this.edit_depense(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button onClick={() => this.conf_delete_depense(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -20 }} icon="pi pi-times" /></div>
        </div>
    }
    delete_depense() {
        if (this.state.selectedDepense == null || this.state.selectedDepense == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.gestGemsysService.DeleteDepenses(this.state.selectedDepense).then(data => this.resultat(data.code, data.contenu));
        }
    }
    conf_delete_depense(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedDepense: data });
            this.setState({ msg: data.numDep });
            this.setState({ visible: true });
        } 
    }
    render() {
        let liste = [{ label: 'Tous', value: 'Tous' }];
        let rliste_type = null;
        rliste_type = liste.concat(this.state.liste_type);
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_depense();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        let footerGroup = <ColumnGroup>
            <Row>
                <Column footer="TOTAL" colSpan={4} footerStyle={{ textAlign: 'center', fontWeight: 'bold' }} />
                <Column footer={this.state.total} style={{ color: '#000000', fontWeight: 'bold' }} />
            </Row>
        </ColumnGroup>;
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title">
                            <div className="grid " >
                                <div className="col-12 md:col-3">
                                    <label htmlFor="dateDep">Date Depense *</label>
                                </div>
                                <div className="col-12 md:col-3" >
                                    <Calendar id="dateDep" dateFormat="dd/mm/yy" value={this.state.dateDep} onChange={(e) => this.setState({ dateDep: e.target.value })} />
                                </div>

                                <div className="col-12 md:col-3">
                                    <label htmlFor="montantDep">Montant *</label>
                                </div>
                                <div className="col-12 md:col-3" >
                                    <InputNumber id="montantDep" value={this.state.montantDep} onChange={(e) => this.setState({ montantDep: e.value })} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <label htmlFor="motif">Motif *</label>
                                </div>
                                <div className="col-12 md:col-3" >
                                    <InputText id="motif" value={this.state.motif} onChange={(e) => this.setState({ motif: e.target.value })} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <label htmlFor="typeDep">Type Depense *</label>
                                </div>
                                <div className="col-12 md:col-3" >
                                    <Dropdown options={this.state.liste_type} value={this.state.typeDep} onChange={(e) => this.setState({ typeDep: e.target.value })} autoWidth={false} filter={true} filterBy='label' filterMatchMode='contains' />
                                </div>
                            </div>
                            <center>
                                <div className="grid" style={{ width: 300, marginTop: '1px' }}>
                                    <div className="col"><Button label="Annuler" icon="pi pi-ban" onClick={() => this.annuler_depense()} /></div>
                                    <div className="col"><Button loading={this.state.showindicator} label="Valider" icon="pi pi-check" onClick={() => this.enreg_depense()} /></div>
                                </div>
                            </center>
                            <div className="grid">
                                <i className="pi pi-search"></i>
                                <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Options de recherche</span>
                                <div className="col-12">
                                    <div className="grid">
                                        <div className="col-12 md:col-3">
                                            <Calendar id="rpd1" placeholder={moment(new Date()).format('DD/MM/YYYY')} dateFormat="dd/mm/yy" value={this.state.rpd1} onChange={(e) => this.setState({ rpd1: e.target.value })} />
                                        </div>
                                        <div className="col-12 md:col-3">
                                            <Calendar id="rpd2" placeholder={moment(new Date()).format('DD/MM/YYYY')} dateFormat="dd/mm/yy" value={this.state.rpd2} onChange={(e) => this.setState({ rpd2: e.target.value })} />
                                        </div>
                                        <div className="col-12 md:col-3" >
                                            <Dropdown value={this.state.rtypeDep} placeholder='Selectionner Un type' id="rtypeDep" options={rliste_type} onChange={(e) => this.setState({ rtypeDep: e.value })} autoWidth={false} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' panelStyle={{ width: '400px' }} />
                                        </div>
                                        <div className='col-12 md:col-2' >
                                            <Button icon='pi pi-search' loading={this.state.showindicator} label='Rechercher' onClick={() => this.get_liste_depenses()} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <DataTable value={this.state.liste_depenses} selectionMode="single" style={{ minHeight: '400px' }} footerColumnGroup={footerGroup}
                                alwaysShowPaginator={false}  onRowClick={e => this.setState({ selectedDepense: e.data })} onRowSelect={e => this.setState({ selectedDepense: e.data })}
                                responsive={true} selection={this.state.selectedDepense} className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={10}>
                                <Column header="Actions" body={this.actionTemplate.bind(this)} style={{ width: '7%' }} />
                                <Column field="dateDep" header="Date " style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="motif" header="Motif" style={{ width: '40%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="idTypeDep.libelleCodif" header="Type" style={{ width: '20%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="montantDep" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: '#000',fontWeight: 'bold' }}>{this.gestGemsysService.formaterValueSep(parseFloat(rowData.montantDep))}</span>
                                    </div>
                                }} header="Montant" style={{ width: '13%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}